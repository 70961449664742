export const UPDATE_REFRESH_INTERVAL = 30;

export const throttle = function(fn, wait) {
    let last = window.performance.now();

    const throttled = function(...args) {
        const now = window.performance.now();

        if (now - last >= wait) {
            fn(...args);
            last = now;
        }
    };

    return throttled;
};

export const clamp = function(number, min, max) {
    number = Number(number);

    return Math.min(max, Math.max(min, isNaN(number) ? min : number));
};

export const percentify = (time, end) => clamp((time / end) * 100, 0, 100).toFixed(2) + '%';