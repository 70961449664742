import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["clock"]
    static values = {deadline: String}

    initialize() {
        let countDownDate = new Date(this.deadlineValue).getTime()
        let clock = this.clockTarget

        let x = setInterval(function () {
            let now = new Date().getTime()
            let distance = countDownDate - now

            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((distance % (1000 * 60)) / 1000)

            clock.innerHTML = hours + "h " + minutes + "m " + seconds + "s "

            if (distance < 0) {
                location.reload()
            }
        }, 1000)

    }


}