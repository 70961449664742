import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["question", "hidden"]
    static values = {index: Number}

    initialize() {
        this.showCurrentQuestion()
        this.maxSeen = this.indexValue
    }

    submit() {
        this.questionTargets.filter((_, index) => {
            return index === this.indexValue
        }).map((element) => {
            element.submit()
        })
    }

    next() {
        this.submit()
        this.indexValue = Math.min(this.questionTargets.length - 1, this.indexValue + 1)
        this.maxSeen = Math.max(this.maxSeen, this.indexValue)
        this.showCurrentQuestion()
    }

    previous() {
        this.submit()
        this.indexValue = Math.max(0, this.indexValue - 1)
        this.showCurrentQuestion()
    }

    finish() {
        if (this.maxSeen < this.questionTargets.length - 1) {
            const remaining = this.questionTargets.length - this.maxSeen - 1
            if (!confirm(`Jūs dar neperžiūrėjote visų klausimų! Ar tikrai norite baigti testą?\n(liko peržiūrėti: ${remaining})`)) {
                return false;
            }
        } else {
            if (!confirm('Ar tikrai norite pateikti visus atsakymus įvertinimui?')) {
                return false;
            }
        }

        this.hiddenTargets.filter((_, index) => {
            return index === this.indexValue
        }).map((element) => {
            element.value = 'finish'
        })
        this.submit()
    }

    showCurrentQuestion() {
        this.questionTargets.forEach((element, index) => {
            element.hidden = index !== this.indexValue
        })
    }
}