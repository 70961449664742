import ApplicationController from "./application_controller"

import consumer from '../channels/consumer'

export default class extends ApplicationController {
    static targets = []
    static values = {
        'course': Number,
    }

    channel;

    connect() {
        super.connect()

    }

    initialize() {
        sleep(50)
        this.channel = consumer.subscriptions.create({
            channel: 'ActivityTrackingChannel',
            room: this.courseValue,
        })
    }

    disconnect() {
        this.channel.unsubscribe()
    }

}

function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}