import videojs from 'video.js'
import { percentify, throttle, UPDATE_REFRESH_INTERVAL } from "../../../utils";

const Component = videojs.getComponent('Component')

export class MaxProgressBar extends Component {
    constructor(player, options) {
        super(player, options);

        this.max_ = options.max || this.player_.currentTime();

        this.update = this.update.bind(this)
        this.getMaxTime = this.getMaxTime.bind(this)
        //this.update = throttle(this.update_, UPDATE_REFRESH_INTERVAL);

        this.on(this.player_, ['ended', 'durationchange', 'timeupdate'], this.update);
    }

    createEl() {
        return super.createEl('div', {
            className: 'vjs-max-progress vjs-slider-bar',
        }, {
            'aria-hidden': 'true'
        });
    }

    update() {
        if (!this.player_.seeking() && !this.player_.scrubbing()) {
            this.max_ = Math.max(this.player_.currentTime(), this.max_);
            this.el().style.width = percentify(this.max_, this.player_.duration())
        } else {
            let rollback = this.max_;
            if (rollback < this.player_.currentTime()) {
                this.player_.currentTime(rollback);
            }
        }
    }

    getMaxTime() {
        return this.max_;
    }
}