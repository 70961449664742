import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["query", "results", "field"]
    static values = {
        category: String,
        sortBy: String,
        sortOrder: String,
        path: String,
    }

    previousState;
a
    initialize() {
        this.resetPreviousState()
        this.boldCategory(this.category)
    }

    disconnect() {
    }

    fetchResults() {
        if (this.query === "") {
            this.reset()
            return
        }

        // intercept if query did not change
        if (this.unchangedFromPreviousState()) {
            return
        }
        this.setPreviousState()

        this.makeQuery()
    }

    navigateResults(event) {
        if (this.searchResultsController) {
            this.searchResultsController.navigateResults(event)
        }
    }

    // private

    reset() {
        // make empty query
        this.makeQuery()

        // this.resultsTarget.innerHTML = ""
        this.query = ""
        this.previousQuery = null
    }

    abortPreviousFetchRequest() {
        if (this.abortController) {
            this.abortController.abort()
        }
    }

    get query() {
        return this.queryTarget.value
    }

    set query(query) {
        this.queryTarget.value = query
    }

    get category() {
        return this.categoryValue
    }

    set category(category) {
        this.categoryValue = category
    }

    get searchResultsController() {
        return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "search-results")
    }

    sort(event) {
        event.preventDefault()
        const element = event.currentTarget
        this.toggleSort(element.dataset.sortBy)
        this.setField(element.innerHTML)
        this.fetchResults()
    }

    categorize(event) {
        event.preventDefault()
        const element = event.currentTarget

        this.toggleCategory(element.dataset.category)
        this.boldCategory(this.category)

        this.fetchResults()
    }

    setField(html) {
        this.fieldTarget.innerHTML = html;
    }

    toggleCategory(category) {
        if (this.category === category) {
            return this.category = undefined
        }
        this.category = category
    }

    boldCategory(id) {
        const activeClasses = ['fw-bold', 'active-category']
        document.querySelectorAll('.category-name').forEach(e => {
            activeClasses.forEach(c => {
                e.classList.toggle(c, false)
            })
        })
        if (id) {
            activeClasses.forEach(c => {
                document.getElementById('category-'+id).classList.toggle(c, true)
            })
        }
    }

    makeQuery() {
        const url = new URL(location.origin + this.pathValue)
        if (this.query !== "") {
            url.searchParams.append("query", this.query)
        }
        if (this.category !== "") {
            url.searchParams.append("category", this.category)
        }
        url.searchParams.append("sort_by", this.sortByValue)
        url.searchParams.append("sort_order", this.sortOrderValue)
        history.replaceState({}, "", url.toString())
        url.searchParams.append("layout", "none")

        this.abortPreviousFetchRequest()
        this.abortController = new AbortController()
        fetch(url, {signal: this.abortController.signal})
            .then(response => response.text())
            .then(html => {
                this.resultsTarget.innerHTML = html
            })
            .catch(() => {
            })
    }

    toggleSort(sortable) {
        if (this.sortByValue === sortable) {
            if (this.sortOrderValue === "desc") {
                this.sortOrderValue = "asc"
            } else {
                this.sortOrderValue = "desc"
            }
        } else {
            this.sortByValue = sortable
            this.sortOrderValue = "desc"
        }
    }

    setPreviousState() {
        this.previousState = {
            category: this.category,
            query: this.query,
            sortBy: this.sortByValue,
            sortOrder: this.sortOrderValue,
        }
    }

    resetPreviousState() {
        this.previousState = {
            category: null,
            query: null,
            sortBy: null,
            sortOrder: null,
        }
    }

    unchangedFromPreviousState() {
        if (this.sortByValue !== this.previousState.sortBy) {
            return false
        }
        if (this.sortOrderValue !== this.previousState.sortOrder) {
            return false
        }
        if (this.category !== this.previousState.category) {
            return false
        }
        if (this.query !== this.previousState.query) {
            return false
        }
        return true
    }

}