import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = ["retakeClock", "retakeButton", "errorMessage"]
    static values = {retakeAt: String}

    initialize() {
        let countDownDate = new Date(this.retakeAtValue).getTime();
        let retakeClock = this.retakeClockTarget
        let retakeButton = this.retakeButtonTarget
        retakeButton.disabled = true;


        let x = setInterval(function () {
            let now = new Date().getTime();
            let distance = countDownDate - now;

            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            retakeButton.value = "Galite perlaikyti už " + days + "d " + hours + "h "
                + minutes + "m " + seconds + "s ";

            if (distance < 0) {
                clearInterval(x);
                retakeButton.value = "Perlaikyti"
                retakeButton.disabled = false;
            }
        }, 1000);

    }


}